* {
	font-family: 'Georgia', serif;
	box-sizing: border-box;
}

:root {
	--bg: #B82554;
	--header: #831A3C;
	--nav: #61132b;
	--text-box: rgba(231, 231, 231, 0.8);
	--text-light: rgba(231, 231, 231, 0.85);
	--text: #000000;
	--header-height: 100px;
}

html {
	background-color: var(--bg);
}

iframe {
	border: none;
}

/* Flexbox classes */
.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-mobile {
	flex-wrap: nowrap;
}

.f-btw {
	justify-content: space-between;
}

.f-arnd {
	justify-content: space-around;
}

.f-align-c {
	align-items: center;
}

.f-align-top {
	align-items: flex-start;
}

.f-align-bottom {
	align-items: flex-end;
}

.break,
.break-2 {
	flex-basis: 100%;
	height: 0;
	display: none;
}

header {
	height: var(--header-height);
	padding: 20px;
	padding-left: 40px;
	background-color: var(--header);
}

.navIcon {
	color: var(--text-light);
	margin-right: 40px;
	transition: .5s;
}

.navIcon:hover,
.navIcon:focus {
	color: var(--bg);
	cursor: pointer;
}

h1,
h2 {
	color: var(--text-light);
}

h1 {
	font-weight: bold;
	margin: 0;
	margin-right: 30px;
	flex: 0 0 300px;
}

h2 {
	text-align: right;
	font-weight: 400;
	font-style: italic;
	font-size: 1.1rem;
	margin: 0;
	margin-bottom: 5px;
}

.header-subtext {
	flex: 1 0 auto;
}

.container-img-quote {
	position: relative;
	text-align: center;
	overflow-x: hidden;
	overflow-y: hidden;
	width: 100%;
}

.hero-img {
	width: auto;
	height: calc(100vh - var(--header-height));
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

/* The side navigation menu */
.sidenav {
	height: 100%;
	/* 100% Full-height */
	width: 280px;
	/* 0 width - change this with JavaScript */
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Stay on top */
	top: 0;
	/* Stay at the top */
	left: 0;
	background-color: var(--nav);
	/* Black*/
	overflow-x: hidden;
	/* Disable horizontal scroll */
	padding-top: 60px;
	/* Place content 60px from the top */
	transform: translate(-280px);
	transition: 0.5s;
	/* 0.5 second transition effect to slide in the sidenav */
}

.open {
	transform: translate(0px);
}

.close {
	transform: translate(-280px);
}

/* The navigation menu links */
.sidenav a {
	padding: 15px 8px 15px 32px;
	text-decoration: none;
	font-size: 1.3rem;
	color: var(--text-light);
	display: block;
	transition: 0.3s;
}

.sidenav a:nth-child(6) {
	border-top: solid 1px;
	padding-top: 15px;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover,
.sidenav a:focus {
	color: #f1f1f1;
	background-color: var(--bg);
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
	position: absolute;
	top: 10px;
	right: 25px;
	font-size: 3rem;
	margin-left: 50px;
	color: var(--text-light);
	transition: .5s;
}

.sidenav .closebtn:hover,
.sidenav .closebtn:focus {
	color: var(--bg);
	cursor: pointer;
}

/* ---------- */

.fullContainer {
	position: relative;
	min-height: calc(100vh - var(--header-height) - 60px);
	padding-bottom: 9.5rem;
}

.pageContainer {
	background-color: var(--text-box);
	width: calc(100% - 30px);
	max-width: 800px;
	margin: 80px auto;
	margin-top: 60px;
	padding: 30px;
	padding-bottom: 2.5rem;
	border-radius: 5px;
}

h3,
h4,
h5 {
	color: var(--text);
	font-weight: bold;
}

h3 {
	font-size: 1.8rem;
	margin: 0;
	margin-bottom: 30px;
}

h4 {
	font-size: 1.3rem;
	font-style: italic;
	margin: 15px 0;
	margin-top: 30px;
}

h5 {
	font-weight: 400;
	font-style: italic;
	font-size: 1rem;
}

.profile-pic {
	width: 250px;
}

.profile-pic-small {
	display: none;
}

.about-text-container {
	flex: 1 1 auto;
}

p,
li {
	margin: 0;
	line-height: 1.5rem;
}

.about-p {
	padding-left: 30px;
}

ul {
	margin: 0;
}

li {
	margin-bottom: 10px;
}

.contact-img {
	display: block;
	margin-top: 20px;
}

.map {
	margin: 20px auto;
	width: 90%;
}

.c-full {
	margin-top: 0px;
	margin-left: auto;
	margin-right: auto;
}

.videoContainer {
	position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

footer {
	background-color: var(--header);
	padding: 40px;
	text-align: right;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.copyright {
	color: var(--text-light)
}

.verified-pic {
	width: 150px;
	margin-left: auto;
}

.verified-pic:hover {
	opacity: .8;
}

/* Top Doctor Badge Styling */
.fatd-badge-outer {
	width: auto;
	background-color: rgba(245, 247, 249, 1);
	border-radius: 10px;
	padding: 3px;
	margin-left: 50px;
}

.fatd-badge-outer:hover {
	background-color: rgba(245, 247, 249, 0.8);
}

.fatd-badge-outer img {
	width: 90px;
}

/* Scroll Bar */
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: var(--bg);
}

::-webkit-scrollbar-thumb {
	background: var(--nav);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--text-box);
}

/* Media queries */
@media screen and (max-width: 960px) {
	header {
		height: 110px;
	}

	.break {
		display: inline;
	}

	.fullContainer {
		min-height: calc(100vh - var(--header-height) - 25px);
	}

	h1 {
		margin: 0;
		margin-bottom: 10px;
		font-size: 1.5rem;
		flex-basis: auto;
	}

	h2 {
		font-size: .8rem;
	}

	.hero-img {
		height: calc(100vh - var(--header-height) - 10px);
	}
}

@media screen and (max-width: 700px) {
	.break-2 {
		display: block;
	}

	.profile-pic {
		display: none;
	}

	.profile-pic-small {
		display: block;
		flex-basis: 400px;
		margin-bottom: 30px;
	}

	.f-arnd {
		justify-content: flex-start;
	}

	.flex-wrap-mobile {
		flex-wrap: wrap;
		justify-content: center;
	}

	.about-text-container {
		flex: 1 0 100%;
	}

	.about-p {
		padding-left: 0;
	}

	.contact-img {
		height: 15px;
	}
}

@media screen and (max-width: 420px) {
	.header-subtext {
		margin-top: 10px;
	}

	h1 {
		font-size: 1.3rem;
	}

	h2 {
		font-size: .6rem;
	}

	.pageContainer {
		margin: 15px;
		margin-bottom: 25px;
		padding: 15px;
	}

	h3 {
		font-size: 1.5rem;
		margin-bottom: 20px;
	}

	.profile-pic-small {
		width: 100%;
		flex-basis: 100%;
	}

	p,
	h5,
	li {
		font-size: .8rem;
	}

	h4,
	h5 {
		margin-top: 20px;
	}

	h4 {
		font-size: 1rem;
	}

	.fatd-badge-outer {
		margin-left: 5px;
	}
	.sx-verified-seal,
	.fatd-badge-outer img {
		scale: 0.8;
	}

}